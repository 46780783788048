import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import {DrivosityCertifiedData} from "./DrivosityCertified.data";

import Cover from "../../../images/OurCompany/DrivosityCertified/certifiedcover.jpeg";
import First from "../../../images/OurCompany/DrivosityCertified/certifiedNewSquare.png";

const DrivosityCertified = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={DrivosityCertifiedData[0].title}
                description={DrivosityCertifiedData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="pt-12 pb-12">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {DrivosityCertifiedData[1].title}
                                </div>
                                <div className='drv-description'>
                                    {DrivosityCertifiedData[1].content}
                                </div>
                            </>
                        ),
                        (
                            <img src={First} alt="Certified mockup from application" />
                        )
                    ]}
                />
            </div>
        </>
    )
}

export default DrivosityCertified;
