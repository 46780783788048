import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import DrivosityCertified from "../components/OurCompany/DrivosityCertified/DrivosityCertified";

import Favicon from "../images/Favicon.png";

const DrivosityCertifiedPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Drivosity Certified - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="DrivosityCertified honors first-party delivery drivers and franchise businesses committed to road safety. Our certification system measures safe driving habits in real time through GPS tracking and reports. Explore how you can be part of the solution for safer roads."/>
            </Helmet>
            <Layout component={<DrivosityCertified/>}/>
        </>
    )
}

export default DrivosityCertifiedPage;
